export const getMessageFromError = (error) => {
  // from rtk query
  if (error?.data?.error) {
    if (Array.isArray(error.data.error)) {
      if (Array.isArray(error.data.error[0])) {
        if (error.data.error[0][0]) {
          return error.data.error[0][0];
        }
      } else {
        if (error.data.error[0]) {
          return error.data.error[0];
        }
      }
    } else {
      if (error.data.error) {
          return error.data.error;
      }
    }
    return 'An unknown error occurred.';
  } 
  
  // Ensure that error.response and error.response.data are defined
  if (error && error.response && error.response.data && error.response.data.error) {
   
    const errorData = error.response.data.error;

    

    if (typeof errorData === 'string') {
      // If error is a string, return it
      return errorData;
    } else if (Array.isArray(errorData)) {
      if (Array.isArray(errorData[0])) {
        // If the first element is an array, return the first element of the nested array
        return errorData[0][0];
      } else {
        // Return the first element of the array
        return errorData[0];
      }
    } else {
      // Handle unexpected formats
      return 'An unknown error occurred.';
    }
  } else {
    if (error.code == 'ERR_NETWORK') {
        return "Check your Internet connection and try again."
    }
    // Handle cases where error.response.data.error is not available
    return 'An unknown error occurred.';
  }
};
