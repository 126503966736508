import { Link, useLocation } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import Landing from '../assets/landing.jpg';

const Header = () => {
  const location = useLocation(); 
 const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header
  className="main-header relative sticky top-0 z-50 transition-all duration-300"
  style={{
    background: isScrolled
      ? 'linear-gradient(to bottom, #770E04, #333)'
      : 'linear-gradient(to right, #0b0b0d, #141415, #770E07)',
    transition: 'all 0.3s',
  }}
>


  

  <div className="py-4">
    <div className="auto-container lg:max-w-[1700px] mx-auto">
      <div className="inner-container flex items-center justify-between"> 
        <div className="logo-box text-white">
          <div className="logo  lg:text-3xl font-bold">
            <Link to="/" className="text-white">
              CLICK COACH
            </Link>
          </div>
        </div>
        <div className="nav-right flex items-center text-white space-x-4 sm:space-x-6">
          <nav className="main-menu flex flex-wrap space-x-4 sm:space-x-6">
            <ul className="navigation flex items-center space-x-4 sm:space-x-6">
           
              <li className="group">
                <Link to="/" className="relative text-sm sm:text-base lg:text-lg">
                  HOME
                  <span className="absolute left-0 right-0 bottom-[-4px] h-[2px] bg-white transition-opacity duration-200 opacity-0 group-hover:opacity-100" />
                  {location.pathname === '/' && (
                    <span className="absolute left-0 right-0 bottom-[-4px] h-[2px] bg-white" />
                  )}
                </Link>
              </li>

           
              <li className="group">
                <Link to="/features&benefits" className="relative text-sm sm:text-base lg:text-lg">
                  WHY CHOOSE US
                  <span className="absolute left-0 right-0 bottom-[-4px] h-[2px] bg-white transition-opacity duration-200 opacity-0 group-hover:opacity-100" />
                  {location.pathname === '/features&benefits' && (
                    <span className="absolute left-0 right-0 bottom-[-4px] h-[2px] bg-white" />
                  )}
                </Link>
              </li>

              <li className="group">
                <Link to="https://www.instagram.com/clickcoach.app?igsh=MTNubTdleDdidXh3MA==" target='_blank' className="relative text-sm sm:text-base lg:text-lg flex items-center">
                  <FaPhone className="text-lg" style={{ transform: 'rotate(90deg)' }} />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>


  );
};

export default Header;
