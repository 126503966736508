import React from 'react';
import FeatureCard from './feature_card'; // Adjust the import path based on your file structure
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faDumbbell } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

const FeaturesBox = () => {
  
  return (
    <div >
       <h2 className="text-4xl text-white font-bold text-center mt-5">FEATURES</h2>
      <div className="flex flex-col lg:flex-row items-center justify-center gap-8 p-8 lg:p-16">
        {/* Feature Cards without box shadow and with hover gradient background */}
        <FeatureCard
          icon={faUser}
          title="EASILY LOG & TRACK TRAINEES"
          description="Manage your trainees with ease—log their details, track their balances, and stay organized."
        />
        <FeatureCard
          icon={faDumbbell}
          title="LOG & MANAGE SESSIONS EFFORTLESSLY"
          description="Quickly log sessions, track paid and pending payments, and manage trainees' balances."
        />
        <FeatureCard
          icon={faFileAlt}
          title="SEND RECEIPTS & BALANCE SHEETS"
          description="Send professional receipts and statements of account to keep your trainees updated with 1 click."
        />
        <FeatureCard
          icon={faChartLine}
          title="MONITOR YOUR PROFITS"
          description="Automatically calculate profits and gain a clear overview of your coaching income."
        />
      </div>
    </div>
  );
};

export default FeaturesBox;
