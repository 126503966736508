import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: false,
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Export actions to be used in components
export const { setStoreData} = storeSlice.actions;

// Export the reducer to be included in the store
export default storeSlice.reducer;
