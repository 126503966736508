import { configureStore } from '@reduxjs/toolkit';
import storeReducer from './redux/storeSlice';
import userReducer from './redux/userSlice';
import { apiSlice } from './redux/api/apiSlice';

const store = configureStore({
  reducer: {
    store: storeReducer,
    user: userReducer,
    // Add the API slice reducer to the store
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  // Add the API slice middleware to enable caching, invalidation, etc.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;