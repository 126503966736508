import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeatureCard = ({ icon, title, description }) => (
  <div
    className={`flex flex-col items-center justify-center text-center p-8 w-[250px] h-[300px] bg-transparent text-white rounded-lg transform hover:scale-105 hover:bg-gradient-to-b hover:from-[#770E04] hover:to-[#C24A42] transition-all duration-300`}
  >
    <div className="text-6xl mb-4">
      <FontAwesomeIcon icon={icon} /> {/* Render the Font Awesome icon here */}
    </div>
    <h3 className="text-lg lg:text-xl font-bold mb-4">{title}</h3>
    <p className="text-sm lg:text-base">{description}</p>
  </div>
);

export default FeatureCard;
