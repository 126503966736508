import { CircularProgress } from "@mui/material";
import React from "react";

const FormSubmitBtn = ({ text, onClick, isLoading, className }) => {
  return (
    <button
      onClick={onClick}
      className={`bg-main text-white px-5 py-1 rounded-md hover:opacity-80 ${className}`}
    >
      {!isLoading ? text : <CircularProgress size={20} color="inherit" />}
    </button>
  );
};

export default FormSubmitBtn;
