import React from 'react';
import { IoIosClose } from "react-icons/io";
import videoSrc from '../../src/assets/video2.mp4';

const TutorialModal = ({ onClose }) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 transition-opacity duration-300 ease-in-out"
      onClick={() => onClose()}
    >
      <div
        className="relative w-full max-w-[95vw] sm:max-w-[600px] max-h-[90vh] bg-white rounded-lg overflow-hidden shadow-2xl transform transition-transform duration-300 ease-in-out scale-100"
        onClick={(e) => e.stopPropagation()}
      >
      
        <div className="flex justify-between items-center p-3 sm:p-4 bg-black">
          <h1 className="lg:text-2xl  text-xl text-white font-semibold text-center w-full">
            A Step-by-Step Guide for Coaches
          </h1>
          <IoIosClose
            size={25}
            className="text-white cursor-pointer hover:text-red-500 transition-colors duration-300 absolute right-3 top-3"
            onClick={() => onClose()}
          />
        </div>

        <div className="relative flex justify-center items-center bg-black">
          <video
            className="w-full h-auto max-h-[60vh] sm:max-h-[80vh] object-contain"
            src={videoSrc}
            autoPlay
            loop
            muted
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default TutorialModal;
