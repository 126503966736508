import React, { useState } from 'react';
import AddTestimonialsModal from './add_testimonials';
import { IoAdd } from 'react-icons/io5';
import { Modal } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'; 
import { Pagination } from 'swiper/modules';
import Comma from '../../assets/comma1.png'
import 'swiper/swiper-bundle.css';

const TestimonialCarousel = ({ displayTestimonials = [], renderStars, onTestimonialAdded }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

  const handleTestimonialAdded = (newTestimonial) => {
    onTestimonialAdded(newTestimonial);
    setIsModalOpen(false); 
    setIsThankYouModalOpen(true); 
  };

  return (
    <div>
      <div className="flex sm:flex-row justify-center items-center mb-4">
        <div className="flex justify-start space-x-3 mb-2 sm:mb-0">
          <p className="text-2xl font-semibold text-white">Share Your Experience</p>
          <div
            className="w-7 h-7 mt-1 bg-red-700 flex items-center justify-center cursor-pointer rounded-full hover:opacity-80 transition-opacity"
            onClick={() => setIsModalOpen(true)}
          >
            <IoAdd color="white" size={20} />
          </div>
        </div>
      </div>

      {Array.isArray(displayTestimonials) && displayTestimonials.length > 0 ? (
  <div className="w-full mx-auto">
    <Swiper
      modules={[Autoplay, Pagination]}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      loop={true}
      breakpoints={{
        640: { slidesPerView: 2, spaceBetween: 10 },
        1024: { slidesPerView: 2, spaceBetween: 10 },
      }}
      className={`lg:max-w-[${displayTestimonials.length === 1 ? '700px' : '800px'}] lg:max-w-[800px] max-w-[300px]`}
    >
      {displayTestimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
        <div
          className="flex flex-col justify-between items-center bg-white rounded-lg shadow-lg overflow-hidden"
          style={{
            height: "270px",
            width:
              window.innerWidth < 640
                ? "300px"
                : displayTestimonials.length === 1
                ? "285px"
                : "390px",
            padding: "10px",
          }}
        >
          <img src={Comma} width={35} alt="Comma" className='lg:mr-80 mr-60 mt-1' />
      
          <div className="text-2xl font-semibold text-[#2d3748] -mt-3"> 
            {testimonial.name}
            <span className="text-xl ml-1">/ {testimonial.sport}</span>
          </div>
      
          <div
            className="text-[#333333] italic text-lg mt-2"
            style={{
              whiteSpace: "pre-wrap",
              fontFamily: 'Arial',
              wordWrap: "break-word",
              overflowY: "auto",
              lineHeight: "1.3",
              maxHeight: "120px",
              maxWidth: "100%",
              paddingBottom: "10px",
            }}
          >
            {testimonial.comment}
          </div>
      
          <div className=" mb-5 self-center flex">
            {renderStars && renderStars(testimonial.rating)}
          </div>
        </div>
      </SwiperSlide>
      
      ))}
    </Swiper>
  </div>
) : (
        <div className="text-center p-6 bg-red-900 rounded-lg shadow-xl">
          <h1 className="text-2xl text-white uppercase relative">
            No testimonials available
          </h1>
        </div>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-comment"
      >
        <div>
          <AddTestimonialsModal
            onClose={() => setIsModalOpen(false)}
            onTestimonialAdded={handleTestimonialAdded}
          />
        </div>
      </Modal>

      <Modal
        open={isThankYouModalOpen}
        onClose={() => setIsThankYouModalOpen(false)}
        aria-labelledby="thank-you-modal-title"
        aria-describedby="thank-you-modal-description"
      >
        <div className="flex flex-col justify-center items-center p-5 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold" id="thank-you-modal-title">Thank You!</h2>
          <p className="mt-2" id="thank-you-modal-description">Your testimonial has been added.</p>
          <button 
            className="mt-4 bg-red-700 text-white py-2 px-4 rounded"
            onClick={() => setIsThankYouModalOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TestimonialCarousel;
