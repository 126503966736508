
import { apiSlice } from "./apiSlice";

export const TestimonialsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
       
        addTestimonials: builder.mutation({
            query: (Testimonials) => ({
                url: '/testimonials',
                method: 'POST',
                body: Testimonials,
            }),
            invalidatesTags: [{ type: 'Testimonials' }],
        }),
        getTestimonials: builder.query({
          query: () => ({
            url: '/testimonials',
            method: 'GET',
          }),
          providesTags:[{ type: 'Testimonials' }]
        }),
    })
})

export const { useAddTestimonialsMutation, useGetTestimonialsQuery } = TestimonialsSlice;
