import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useAddTestimonialsMutation } from "../../redux/api/Testimonials";
import { TextField, Modal } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import ThankYouModal from '../testimonials/thanks';
import { getMessageFromError } from '../../utils/utils';
import FormSubmitBtn from "../buttons/form_submit_btn/form_submit_btn";

const AddTestimonialsModal = ({ onClose }) => {
  const [name, setName] = useState("");
  const [sport, setSport] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addTestimonial, { isLoading: isAddLoading }] = useAddTestimonialsMutation();
  const [errMsg, setErrMsg] = useState('');

  // Validation states
  const [nameError, setNameError] = useState("");
  const [sportError, setSportError] = useState("");
  const [commentError, setCommentError] = useState("");

  const validateForm = () => {
    let valid = true;
    
    if (!name) {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (!sport) {
      setSportError("Sport is required");
      valid = false;
    } else {
      setSportError("");
    }

    if (!comment) {
      setCommentError("Comment is required");
      valid = false;
    } else {
      setCommentError("");
    }

    return valid;
  };

  const handleAddTestimonial = async () => {
    if (!validateForm()) return;

    try {
      await addTestimonial({
        name: name,
        sport: sport,
        comment: comment,
        rating: rating,
      }).unwrap();
      
      // Reset the form fields
      setName("");
      setSport("");
      setComment("");
      setRating(0);

      // Open the thank you modal
      setIsModalOpen(true);
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  return (
    <div
      className="fixed inset-0 flex py-10 px-5 sm:px-0 items-center justify-center bg-black bg-opacity-50"
      onClick={() => onClose()}
    >
      <div
        className="relative w-[500px] max-h-[90vh] bg-white rounded-lg shadow-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between px-6 pt-6 bg-gradient-to-r from-green-400 to-blue-500 text-white">
          <p className="font-bold text-lg">Leave a Comment</p>
          <IoIosClose
            size={30}
            className="cursor-pointer hover:text-gray-200 transition"
            onClick={() => onClose()}
          />
        </div>
        <div className="p-6 overflow-y-auto max-h-[70vh]">
          <div className="space-y-4">
            <div>
              <label className="font-semibold">Full Name</label>
              <TextField
                value={name}
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                className="mt-1"
                error={!!nameError}
                helperText={nameError}
              />
            </div>
            <div>
              <label className="font-semibold">Sport</label>
              <TextField
                value={sport}
                placeholder="Sport"
                onChange={(e) => setSport(e.target.value)}
                variant="outlined"
                fullWidth
                className="mt-1"
                error={!!sportError}
                helperText={sportError}
              />
            </div>
            <div>
              <label className="font-semibold text-gray-700">Comment</label>
              <TextareaAutosize
                value={comment}
                placeholder="Enter your comment here..."
                onChange={(e) => setComment(e.target.value)}
                minRows={4}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '6px',
                  border: '1px solid #d1d5db',
                  fontSize: '16px',
                  backgroundColor: '#f9fafb',
                  transition: 'border-color 0.3s',
                }}
                className="mt-1 focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              />
              {commentError && <p className="text-red-500 mt-1">{commentError}</p>}
            </div>

            <div className="flex mb-4 justify-center items-center">
              <span className='text-xl mr-2'>Rate Us:</span>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => setRating(star)}
                  className={`cursor-pointer text-3xl ${star <= rating ? 'text-yellow-500' : 'text-gray-400'}`}
                >
                  ★
                </span>
              ))}
            </div>

            {errMsg && <p className="text-red-500 mt-3 text-center">{errMsg}</p>}

            <div className="flex justify-end my-3 space-x-2">
              <FormSubmitBtn
                onClick={handleAddTestimonial}
                text="Add"
                isLoading={isAddLoading}
                className="bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded-md transition duration-200"
              />
            </div>
          </div>
        </div>

        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-comment"
        >
          <div>
            <ThankYouModal onClose={() => { setIsModalOpen(false); onClose(); }} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AddTestimonialsModal;
