import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    logOutUser: (state) => {
      state.data = null; // to re-render pages accessing data property
      state = initialState;
    }
  },
});

// Export actions to be used in components
export const { setUserData, logOutUser } = userSlice.actions;
 
// Export the reducer to be included in the store
export default userSlice.reducer;
