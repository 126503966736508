import React, { useEffect, useState ,useRef} from 'react';
import Header from '../components/header'
import { FaArrowAltCircleUp } from 'react-icons/fa';
import FeaturesBox from '../components/features_box';
import Slider1 from '../assets/fast1.jpeg'
import Phone from '../../src/assets/phone.png';
import Laptop from '../../src/assets/laptop.webp';
import Laptop1 from '../../src/assets/laptop1.png';
import Laptop2 from '../../src/assets/laptop2.png';
import Slider2 from '../assets/fast2.jpeg'
import Slider3 from '../assets/fast3.jpeg'
import U1 from '../assets/u1.jpeg';
import U2 from '../assets/u2.jpeg';
import U3 from '../assets/u3.jpeg';
import U4 from '../assets/u4.jpeg';
import U5 from '../assets/u5.jpeg';
import Landing from '../assets/landing.jpg';

import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'; 

const Features_Benefits = () => {
  const sliders = [Slider1, Slider2, Slider3]; 
  const [currentSlide, setCurrentSlide] = useState(1); 

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % sliders.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + sliders.length) % sliders.length);
  };

  const sliders2 = [U1, U2, U3,U4,U5]; 
  const [currentSlide2, setCurrentSlide2] = useState(1); 

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % sliders2.length);
  };

  const prevSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide - 1 + sliders2.length) % sliders2.length);
  };
  const sliders3 = [Laptop1, Laptop2]; 
  const [currentSlide3, setCurrentSlide3] = useState(1); 

  const nextSlide3 = () => {
    setCurrentSlide3((prevSlide) => (prevSlide + 1) % sliders3.length);
  };

  const prevSlide3 = () => {
    setCurrentSlide3((prevSlide) => (prevSlide - 1 + sliders3.length) % sliders3.length);
  };
  const [showScroll, setShowScroll] = useState(false);
  const headerRef = useRef(null);
  const scrollLinkRef = useRef(null);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <><Header ref={headerRef}/>
    <div
    className="h-auto bg-gradient-to-b from-[#770E04] to-[#333] text-white flex flex-col justify-center items-center relative"
    style={{ backgroundImage: `url(${Landing})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >
      <FeaturesBox/>
      <div className="container mx-auto px-8 py-12 text-white space-x-2">
  <h2 className="text-4xl font-bold text-center mb-10">BENEFITS</h2>
  <div className="flex flex-col lg:flex-row lg:space-x-10">
    <p className="w-full lg:w-1/2 pr-4 lg:pr-2 mb-4 lg:mb-0 text-2xl text-center lg:mt-48 text-2xl lg:mr-10 mt-10 mr-10" style={{fontFamily:'Arial'}}>
     <span className='text-3xl font-bold'>FASTER THAN A SPRINT FINISH!</span> 
      <br />
      From trainee registration to sessions and payments log, we help you track all your transactions with the blink of an eye.
    </p>
    
    <div className="relative w-full lg:w-[650px] h-[650px] mb-20 lg:mb-0 lg:mb-[-20px] mt-5">

    <div className="relative lg:w-[500px] w-[300px] h-[500px] mb-20 lg:mb-0 mx-auto lg:mt-[-10px] order-2 lg:order-2">
    <img src={Phone} alt="Phone Frame" className="absolute z-10" />
    <img src={sliders[currentSlide]} className="absolute lg:top-[3.5%] top-[3.4%] lg:left-[3.6%] left-[6.3%] lg:w-[63%] w-[87%] lg:h-[670px] h-[567px] object-cover" autoPlay loop muted />
  </div>
  <div className="absolute bottom-[-50px]  lg:ml-20 lg:bottom-[-120px] lg:mr-64  left-0 right-0 flex justify-center">
    <button
      onClick={prevSlide}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2 mr-20"
    >
      <FaChevronLeft />
    </button>
    <button
      onClick={nextSlide}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2"
    >
      <FaChevronRight />
    </button>
  </div>
</div>
<div className="flex flex-col lg:flex-row justify-start items-start lg:ml-64">
    <p className="w-full lg:w-1/2 pr-4 lg:pr-2 mb-4 lg:mb-0 text-2xl text-center lg:mt-48 text-2xl lg:mr-10 mt-10 mr-10" style={{fontFamily:'Arial'}}>
    <span className='text-3xl font-bold'>UPGRADE YOUR COACHING GAME</span> 
      <br />
Never miss a payment again!
With Click Coach check your pending payments, calculate your profit and income in seconds.
    </p>
    
    <div className="relative w-full lg:w-[250px] h-[650px] mb-20 lg:mb-0 lg:mb-[-20px] lg:ml-4 mt-5">
    <div className="relative lg:w-[500px] w-[300px] h-[500px] mb-20 lg:mb-0 mx-auto lg:mt-[-10px] order-2 lg:order-2">
    <img src={Phone} alt="Phone Frame" className="absolute z-10" />
    <img src={sliders2[currentSlide2]} className="absolute lg:top-[4%] top-[3.4%] lg:left-[3.6%] left-[6.3%] lg:w-[63%] w-[87%] lg:h-[665px] h-[567px] object-cover" autoPlay loop muted />
  </div>
  <div className="absolute bottom-[-50px]  lg:ml-20 lg:bottom-[-120px]  left-0 right-0 flex justify-center">
    <button
      onClick={prevSlide2}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2 mr-20"
    >
      <FaChevronLeft />
    </button>
    <button
      onClick={nextSlide2}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2"
    >
      <FaChevronRight />
    </button>
  </div>
</div>

  </div>
  </div>
  <div className="container lg:mt-64">
  <div className='text-center'>
    <p style={{ fontFamily: 'Arial' }}>
      <span className="lg:text-3xl font-bold">
        FOCUS ON COACHING... WE HANDLE THE REST
      </span>
      <br />
      Forget about paper receipts! 
      Don’t just train like a pro — <br /> send professional receipts and statements of accounts to give the best image.
      <br />
      <br />
    </p>

    <div className="relative">
  <img
    src={Laptop}
    alt="Phone Frame"
    className="lg:w-[1300px] h-auto lg:h-[800px] lg:ml-20" 
  />
  <img
    src={sliders3[currentSlide3]}
    alt="Chart"
    className="absolute lg:top-[13.6%] lg:left-[21.6%] lg:w-[56%] top-[13.6%] left-[18.6%] w-[63%] lg:h-[480px] h-[137px]" 
  />
  <div className="absolute sm:bottom-[-80px] mb-20 left-0 right-0 flex justify-center">
    <button
      onClick={prevSlide3}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2 lg:mr-32 mr-14"
    >
      <FaChevronLeft />
    </button>
    <button
      onClick={nextSlide3}
      className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2"
    >
      <FaChevronRight />
    </button>
  </div>
</div>

  </div>
</div>

</div>
<div
      ref={scrollLinkRef}
      className="fixed bottom-5 right-5 flex justify-end"
      style={{ display: showScroll ? 'block' : 'none' }}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <FaArrowAltCircleUp
        className="text-4xl text-red-800 hover:text-black hover:scale-110 transition-transform duration-300"
      />
    </div>
    </div>
    </>
  );
};

export default Features_Benefits;
