import { apiSlice } from './apiSlice';

export const FAQApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFAQs: builder.query({
      query: () => ({
        url: `/faq`,
        method: 'GET',
      }),
    }),
  }),
});

// Export hooks for the injected endpoints
export const { useGetFAQsQuery} = FAQApi;
