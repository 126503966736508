import React from "react";
import { IoIosClose } from "react-icons/io";

const ThankYouModal = ({onClose }) => {

  return (
    <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-md transition-opacity duration-300 ease-in-out"
    onClick={() => onClose()}
  >
    <div
      className="relative w-full max-w-md max-h-[90vh] px-10 py-12 bg-gradient-to-br from-purple-600 via-pink-500 to-blue-500 rounded-3xl overflow-hidden shadow-2xl transform transition-all duration-500 hover:scale-105 hover:shadow-3xl"
      onClick={(e) => e.stopPropagation()}
    >
  
      <div className="absolute top-4 right-4">
        <IoIosClose
          size={36}
          className="text-white cursor-pointer hover:text-gray-300 transition-transform transform hover:scale-110"
          onClick={() => onClose()}
        />
      </div>
  

      <div className="flex flex-col items-center h-full justify-center space-y-3">
 
        <div className="text-center px-8 py-8 bg-white bg-opacity-95 rounded-2xl shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500">
            Thank You!
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            We appreciate your feedback and support!
          </p>
        </div>

        <div className="w-32 h-1 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full"></div>

        <div className="text-lg font-light text-white animate-pulse ">
          Your feedback helps us improve!
        </div>
      </div>
    </div>
  </div>
  
  

  );
};

export default ThankYouModal;
