import Header from '../../src/components/header';
import React, { useEffect, useState, useRef } from 'react';
import Phone from '../../src/assets/phone.png';
import videoSrc from '../../src/assets/video2.mp4';
import { useGetFAQsQuery } from "../redux/api/FAQ";
import { IoVideocam } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import TestimonialCarousel from '../components/testimonials/Carousel';
import TutorialModal from '../components/tutorial'
import {useGetTestimonialsQuery} from '../redux/api/Testimonials'
import FeaturesBox from '../components/features_box';
import Slider1 from '../assets/fast1.jpeg';
import Slider2 from '../assets/fast2.jpeg';
import Slider3 from '../assets/fast3.jpeg';
import Landing from '../assets/landing.jpg';
import { Modal } from '@mui/material';
import { FaArrowAltCircleUp, FaChevronRight, FaChevronLeft } from 'react-icons/fa';

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const {data:testimonialsResponse} = useGetTestimonialsQuery();
  useEffect(() => {
    setTestimonials(testimonialsResponse?.data);
  }, [testimonialsResponse]);

  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [faqs, setFAQS] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: FAQs } = useGetFAQsQuery();
  const [showScroll, setShowScroll] = useState(false);


  
  const headerRef = useRef(null);
  const scrollLinkRef = useRef(null);


  const handleScroll = () => {
    const position = window.pageYOffset;
    setShowScroll(position > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (FAQs?.data) {
      setFAQS(FAQs.data);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [FAQs]);

  const sliders = [Slider1, Slider2, Slider3];
  const [currentSlide, setCurrentSlide] = useState(1);

  const nextSlide = () => setCurrentSlide((prevSlide) => (prevSlide + 1) % sliders.length);
  const prevSlide = () => setCurrentSlide((prevSlide) => (prevSlide - 1 + sliders.length) % sliders.length);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep < 3) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        setIsVisible(false);
        setTimeout(() => {
          setCurrentStep(0);
          setIsVisible(true);
        }, 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStep]);

  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => setToggle((prev) => !prev), 2000);
    return () => clearInterval(intervalId);
  }, []);
  const [visibleCount, setVisibleCount] = useState(5);
  const [activeIndex, setActiveIndex] = useState(null);

  const loadMore = () => {
    if (visibleCount + 5 <= faqs.length) {
      setVisibleCount(visibleCount + 5);
    } else {
      setVisibleCount(faqs.length);
    }
  };

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const showLess = () => {
    setVisibleCount(5); 
  };
  const renderStars = (rating) => {
    return (
      <div className="flex space-x-1">
        {[...Array(5)].map((_, index) => (
          <span key={index}>
           <span style={{ fontSize: '24px' }}>
  {index < rating ? '⭐' : <span style={{ fontSize: '27px' }}>☆</span>}
</span>

          </span>
        ))}
      </div>
    );
  };
  return (
    <>
   {loading ? (
  <div className="text-center">
  <div className="flex items-center justify-center h-screen">
  <div className="relative w-12 h-12 animate-spin duration-[4000ms]">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-red-800 rounded-full animate-pulse"></div>
    <div className="absolute bottom-0 left-0 w-3 h-3 bg-red-800 rounded-full animate-pulse delay-150"></div>
    <div className="absolute bottom-0 right-0 w-3 h-3 bg-red-800 rounded-full animate-pulse delay-300"></div>
  </div>
</div>
  </div>
) : (

      <div>
        <Header ref={headerRef} />
        <div
      className="h-auto bg-gradient-to-b from-[#770E04] to-[#333] text-white flex flex-col justify-center items-center relative"
      style={{ backgroundImage: `url(${Landing})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
        <div className="container flex flex-col lg:flex-row justify-center items-center lg:space-x-32 mb-0 lg:mb-32 lg:mt-20 mx-auto lg:max-w-5xl"> 
  <div className="max-w-lg lg:w-1/3 mb-8 lg:mb-0 text-center lg:text-left order-1 lg:order-1 mt-5">
    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold leading-tight mb-6">STEP INTO THE NEW ERA OF COACHING</h1>
    <p className="text-md sm:text-lg lg:text-xl mb-6">A PLATFORM BUILT BY COACHES... FOR COACHES</p>
    <button className="bg-red-800 text-white px-8 py-3 rounded-lg shadow-xl hover:bg-red-700 transition-all border border-red-700">
      <Link to="https://coach.clickcoach.app/" target="_blank">{toggle ? "SIGN UP" : "LOGIN"}</Link>
    </button>
    <div className="flex justify-start space-x-3 mb-2 sm:mb-0 mt-5 ml-20 lg:ml-0 mr-10 lg:mr-0">
      <div
        className="w-7 h-7 mt-1 bg-red-700 flex items-center justify-center cursor-pointer rounded-full hover:opacity-80 transition-opacity"
        onClick={() => setIsModalOpen(true)}
      >
        <IoVideocam color="white" size={20} />
      </div>
      <p className="text-2xl font-semibold text-white">See how it works <span className='text-gray-300'>(1:21)</span> </p>
    </div>
  </div>

  <div className="relative lg:w-[500px] w-[300px] h-[500px] mb-20 lg:mb-0 mx-auto lg:mt-[-10px] order-2 lg:order-2">
    <img src={Phone} alt="Phone Frame" className="absolute z-10" />
    <video src={videoSrc} className="absolute lg:top-[2.5%] top-[3.4%] lg:left-[6.6%] left-[6.3%] lg:w-[86.5%] w-[87%] lg:h-[420px] h-[567px] object-cover" autoPlay loop muted />
  </div>

  <div className="flex flex-col items-center sm:items-center lg:items-start text-center lg:text-left space-y-4 sm:space-y-4 lg:space-y-8 lg:w-1/2 sm:mt-4 lg:mt-0 order-3 sm:order-2 lg:order-3 mt-20 lg:mt-0 lg:mb-0 mb-5">
  {["TRACK SESSIONS", "MANAGE PAYMENTS", "SEND RECEIPTS", "SIMPLE & FREE"].map((stepText, index) => (
    <div
      key={index}
      className={`flex items-center justify-center w-full sm:w-auto lg:w-full lg:justify-start ${index === 1 ? 'ml-10 lg:ml-0' : ''} ${index === 0 ? 'ml-4 lg:ml-0' : ''} ${index === 3 ? 'mr-3 lg:ml-0' : ''}`}
      style={{
        opacity: index <= currentStep ? (isVisible ? 1 : 0) : 0,
        transition: "opacity 1s ease-in-out",
      }}
    >
      <div className="flex items-center justify-center w-10 h-10 bg-red-500 rounded-full text-white font-bold mr-5 sm:-ml-6 lg:ml-0">
        {index + 1}
      </div>
      <p className="text-lg">{` ${stepText}`}</p>
    </div>
  ))}
</div>




</div>


          <FeaturesBox />
          <div className="w-full text-center text-2xl">
            <Link to="/features&benefits" className=" bg-white text-[#770E04] font-semibold py-2 px-4 rounded-lg shadow-lg hover:bg-red-700 hover:text-white transition duration-300 mb-5">
              View More
            </Link>
          </div>

          <div className="container mx-auto px-8 py-12 mt-5">
            <h2 className="text-4xl font-bold text-center mb-10">BENEFITS</h2>
            <div className="flex flex-col lg:flex-row justify-start items-start lg:ml-32">
              <p className="w-full lg:w-1/2 pr-4 lg:pr-2 mb-4 lg:mb-0 text-2xl text-center lg:mt-48 lg:mr-10" style={{ fontFamily: 'Arial' }}>
                <span className="text-3xl font-bold">FASTER THAN A SPRINT FINISH!</span>
                <br />
                From trainee registration to sessions and payments log, we help you track all your transactions with the blink of an eye.
                <br /><br />
                <Link to="/features&benefits" className="bg-white text-[#770E04] font-semibold py-2 px-4 rounded-lg shadow-lg hover:bg-red-700 hover:text-white transition duration-300">
                  View More
                </Link>
              </p>

              <div className="relative w-full lg:w-[280px] h-[620px] mb-20 lg:mb-0 lg:mb-[-20px] lg:ml-4 mt-5">
                <div className="relative lg:w-[500px] w-[300px] h-[500px] mb-20 lg:mb-0 mx-auto lg:mt-[-10px] order-2 lg:order-2">
    <img src={Phone} alt="Phone Frame" className="absolute z-10" />
    <img src={sliders[currentSlide]} className="absolute lg:top-[3.5%] top-[3.4%] lg:left-[3.6%] left-[6.3%] lg:w-[63%] w-[87%] lg:h-[670px] h-[567px] object-cover" autoPlay loop muted />
  </div>
                <div className="absolute bottom-[-50px]  lg:ml-20 lg:bottom-[-120px]  left-0 right-0 flex justify-center">
                  <button onClick={prevSlide} className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2 mr-20">
                    <FaChevronLeft />
                  </button>
                  <button onClick={nextSlide} className="bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 mx-2">
                    <FaChevronRight />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto lg:mt-32">
      <h2 className="text-4xl font-bold text-center mb-6 mt-10">Frequently Asked Questions</h2>
      <div className="flex flex-col space-y-4">
        {faqs.slice(0, visibleCount).map((faq, index) => (
          <div key={index} className="hover:bg-red-800 text-white p-6 rounded-lg shadow-lg">
            <h3 className="font-semibold flex items-center cursor-pointer text-xl" onClick={() => toggleAnswer(index)}>
              {faq.question}
              <span
                style={{ transition: 'transform 0.5s ease', transform: activeIndex === index ? 'rotate(0deg)' : 'rotate(180deg)', marginLeft: 'auto', fontSize: '1.2rem' }}
              >
                ⌃
              </span>
            </h3>
            <div
              style={{ maxHeight: activeIndex === index ? '200px' : '0', overflow: 'hidden', transition: 'max-height 0.5s ease, opacity 0.5s ease', opacity: activeIndex === index ? 1 : 0 }}
            >
              <p className="mt-2" style={{ fontSize: '16px' }}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>

      {visibleCount < faqs.length ? (
        <div className="mt-8 w-full flex justify-end">
          <button onClick={loadMore} className="bg-white text-[#770E04] font-semibold py-2 px-4 rounded-lg shadow-lg hover:bg-red-700 hover:text-white transition duration-300 mb-10 lg:text-xl text-lg mr-2">
            Load More
          </button>
        </div>
      ) : (
        <div className="mt-8 w-full flex justify-end">
          <button onClick={showLess} className="bg-white text-[#770E04] font-semibold py-2 px-4 rounded-lg shadow-lg hover:bg-red-700 hover:text-white transition duration-300 mb-10 lg:text-xl text-lg mr-2">
            Show Less
          </button>
        </div>
      )}
    </div>
    <div className="testimonial-section py-10">
      <div className="auto-container mx-auto px-4">
        <h2 className="sec-title text-center mb-8">
          <span className="title text-lg font-semibold text-white mb-5" style={{ fontSize: '50px' }}>
            Testimonials
          </span>
        </h2>

        <TestimonialCarousel displayTestimonials={testimonials} renderStars={renderStars} />
      </div>
    </div>
  
    <div className='mb-1'>
      <h2 className="lg:text-6xl text-3xl font-bold text-center mb-6 mt-10">DON’T MISS OUT ON</h2>
      <h2 className="lg:text-5xl  text-2xl font-bold text-center mb-6 mt-10">THE FUTURE OF</h2>
      <h2 className="lg:text-8xl  text-6xl font-bold text-center mb-6 mt-10 text-red-600">COACHING</h2>
      <h2 className="lg:text-4xl  text-2xl  font-bold text-center mb-6 mt-10">ELEVATE YOUR GAME NOW!</h2>
      <button className='lg:text-3xl  text-xl font-bold text-center mb-6 bg-red-500 lg:ml-48 ml-20 rounded-full hover:bg-red-600 transition duration-300 ease-in-out py-2 px-2 mr-5'>
  <Link to='https://coach.clickcoach.app/'>START NOW</Link>
</button>

    </div>
          <div ref={scrollLinkRef} className="fixed bottom-5 right-5 flex justify-end" style={{ display: showScroll ? 'block' : 'none'}} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <FaArrowAltCircleUp className="text-4xl lg:text-6xl text-red-800 hover:text-black hover:scale-110 transition-transform duration-300" />
          </div>
        
        </div>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-comment"
        >
          <div>
            <TutorialModal
              onClose={() => setIsModalOpen(false)}
            />
          </div>
        </Modal>
     
      </div>
       )}
    </>
  );
}

export default Home;
