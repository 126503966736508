import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie'; // Importing js-cookie library
import { BASE_URL } from '../../utils/constants';

// Helper function for setting cookies
const setCookie = (key, value, expiresInDays = 7) => {
  try {
    if (value) {
      Cookies.set(key, value, { expires: expiresInDays, path: '/' }); // Set path for accessibility
      console.log(`Token successfully saved in cookies under key: ${key}`);
    } else {
      console.warn("Attempted to save an invalid value in cookies");
    }
  } catch (error) {
    console.error(`Failed to save token in cookies: ${error.message}`);
  }
};

// Helper function for getting cookies
const getCookie = (key) => {
  try {
    const value = Cookies.get(key);
    if (value) {
      console.log(`Retrieved value from cookies for key: ${key}`);
    } else {
      console.warn(`No value found in cookies for key: ${key}`);
    }
    return value;
  } catch (error) {
    console.error(`Failed to get value from cookies: ${error.message}`);
    return null;
  }
};


// Function to get a new token from the server
const getToken = async () => {
  try {
    const body = new URLSearchParams({
      client_id: 'shopping_oauth_client',
      client_secret: 'shopping_oauth_secret',
      grant_type: 'client_credentials',
    });

    const response = await fetch(`${BASE_URL}/oauth2/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body.toString(),
    });

    const data = await response.json();

    // Debug logging the full response
    console.log("Token response from server:", data);

    // Check if the access token exists in the response
    if (data && data.success === 1 && data.data && data.data.access_token) {
      return data.data; // Return the data containing the access token
    } else {
      console.error('Token response format is unexpected or access token is missing.');
      throw new Error('Token not received');
    }
  } catch (error) {
    console.error('Error fetching token:', error.message);
    throw error;
  }
};

// Base query for Redux Toolkit Query using `fetchBaseQuery`
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = getCookie('token'); // Use cookie key directly
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Accept', 'application/json');
    return headers;
  },
});

// Define the API slice
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions); // Use the baseQuery here

    // Handle 401 Unauthorized responses
    if (result.error && result.error.status === 401) {
      // Call the getToken function to refresh the token
      try {
        const responseData = await getToken();

        if (responseData.access_token) {
          setCookie('token', responseData.access_token); // Save the new token

          // Retry the original query with the new token
          result = await baseQuery(args, api, extraOptions); // Retry with new token
        } else {
          console.warn('No token received after refresh, redirecting to login.');
          return result; // Return the original error
        }
      } catch (error) {
        console.error('Error during token refresh:', error);
        return result; // Return the original error
      }
    }
    
    return result; // Return the result, whether successful or not
  },
  endpoints: () => ({}),
});

// Export the slice for integration with the store
export default apiSlice;
