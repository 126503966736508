
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Features_Benefits from './pages/Features_Benefits';
import Home from './pages/Home';

function App() {

  return (
    <>
<Router>
      <Routes>
	  <Route path="/" element={<Home />} />
        <Route path="/features&benefits" element={<Features_Benefits />} />
      </Routes>
    </Router>

   </>
  );
}

export default App;
